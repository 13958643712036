import { defineComponent } from "@vue/composition-api";
import AppIconCheck from "@/components/global/icons/Check.vue";
export default defineComponent({
    name: "EventSidebar",
    components: {
        AppIconCheck,
    },
    data: () => ({
        links: [
            { name: "map", title: "Карта" },
            { name: "create-event", title: "Створити «Подію»" },
            { name: "new-events", title: "Нові «Події»" },
            { name: "active-events", title: "Поточні «Події»" },
            { name: "closed-events", title: "Журнал «Подій»" },
            { name: "canceled-events", title: " Скасовані «Події»" },
        ],
        createEventRoutes: ["create-event", "activate-event"],
        createEventSubMenu: [
            // { name: "tracker", title: "Сигнал SOS туристичного трекеру" },
            // { name: "application", title: "Сигнал SOS мобільного додатку" },
            { name: "auto", title: "Сигнал SOS" },
            { name: "custom", title: "Створено черговим" },
        ],
    }),
    computed: {
        isCreateEventRoute() {
            var _a;
            return this.createEventRoutes.includes((_a = this.$route.name) !== null && _a !== void 0 ? _a : "");
        },
        selectedCreateEventSubMenu() {
            if (this.$route.name === "create-event")
                return "custom";
            if (this.$route.name === "activate-event")
                return "auto";
            return "custom";
        },
    },
    methods: {
        showCreateEventSubMenu(link) {
            return this.isCreateEventRoute && this.createEventRoutes.includes(link.name);
        },
    },
});
